<template>
  <div>
    <div class="px-3 px-xl-4 mb-8">
      <div class="position-relative">
        <PuSkeleton
          height="35px"
          width="100%"
          class="d-block w-100 rounded-skeleton"
        />
      </div>
    </div>
    <div class="px-3 px-xl-4">
      <b-row
        v-for="(_, i) in 5"
        :key="i"
        align-v="center"
        class="border-radius-10 pl-4 pr-3 py-4 mb-2"
        no-gutters
      >
        <PuSkeleton
          height="2.25rem"
          width="2.25rem"
          circle
          class="col-auto d-block"
        />
        <b-col class="ml-2">
          <PuSkeleton height="18px" width="120px" class="d-block mb-1" />
          <PuSkeleton height="16px" width="100%" class="d-block w-100" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatSidebarLoading"
};
</script>

<style scoped></style>
